// Colors
$primary: #0a2a5a;
$blue: #3d9af1;
$purple: #4420d8;
$purple-aux: #917dff;
$yellow: #ffbe44;
$pink: #ff2972;
$white: #fff;
$black: #000;

// Fonts (use typography.js)
$font-size-base: 16px;
$font-size-h1: 3.375em;
// $font-family-sans-serif: 'Open Sans', sans-serif;
$line-height-base: 1.42;

// Responsive
$screen-xs: 576px;
$screen-sm-min: 768px;
$screen-sm-max: 992px;
$screen-md-max: 1280px;

.standardMargin {
  margin: 16px;
}
