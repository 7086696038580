@import './layout/variables.scss';

h1 {
  margin: auto;
  margin: 20px auto;
  text-align: center;
}

.App {
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: normal;
}

.App > div {
  margin: 10px 0;
}

code {
  background: #fefefe;
  box-shadow: 0 1px 1px 2px #ccc;
  padding: 10px;
  font-size: 14px;
  width: 100%;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wrapper {
  min-height: 80vh;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

section {
  font-family: 'Open Sans', sans-serif !important;
}

.setup,
.contracts {
  width: 100%;
  padding: 40px 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .notice {
    margin: 15px 0;

    p {
      padding: 10px;
      background: #ededed;
      border: 1px solid #ddd;
      color: #444;
    }
  }

  .widgets {
    display: flex;
    max-width: 800px;
    width: 100%;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 0;
  }
}

.loader {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding: 40px 0;
  h3 {
    margin-top: 30px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.appWrapper {
  background: linear-gradient(360deg, #2eb62c, #89ad73 5%, #83d475 15%, #abe098 25%, #c5e8b7 40%);
}
