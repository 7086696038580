.BalancesPanel {
  width: 46%;
  margin: 2%;
  height: 325px;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.5) 1px 1px 8px -4px, rgba(0, 0, 0, 0.5) 1px 1px 4px -4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background: white;
  vertical-align: top;
  font-family: 'Open Sans', sans-serif;
  padding: 20px 3% 8px;
}

.BalancesPanel.disabled {
  opacity: 0.5;
}

@media (max-width: 540px) {
  .BalancesPanel {
    margin-bottom: 64px;
  }
}

.balanceLoadingProgress {
  width: 20px !important;
  height: 20px !important;
  margin-left: 8px !important;
}

.title {
  width: 100%;
  text-align: left;
  font-size: 1.3em;
  margin-bottom: 5px;
}

.bottomBorder {
  height: 2px;
  background: #6ab83a;
  width: 50px;
  margin-bottom: 18px;
}

.balanceRow {
  width: 100%;
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
  margin-bottom: 6px;
}

.balanceRow:last-of-type {
  border: none;
}

.asset {
  width: fit-content;
  font-weight: 100;
  font-size: 16px;
}

.amount {
  width: fit-content;
  font-weight: 400;
  font-size: 16px;
  text-align: right;
}

.underlyingValue {
  color: #c1c5d0;
  font-weight: 100;
}

.light {
  font-weight: 100;
}

@media (max-width: 900px) {
  .BalancesPanel {
    width: 80%;
  }
}

@media (max-width: 700px) {
  .BalancesPanel {
    width: 90%;
    padding: 20px 5% 3px;
  }
}

@media (max-width: 550px) {
  .BalancesPanel {
    width: 95%;
    height: fit-content;
  }
}
